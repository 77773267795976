const staticMenu = document.querySelector('.navmenu');

if (staticMenu) {
    let showing = false;
    const mm = document.createElement('div');
    mm.className = 'narrowmenu';

    const trigger = document.createElement('a');
    trigger.innerHTML = 'Menu';  // TODO: nav
    trigger.className = 'menutrigger';

    mm.appendChild(trigger);

    const newMenu = staticMenu.cloneNode(true);
    mm.appendChild(newMenu);

    staticMenu.className += ' widemenu';

    const parent = staticMenu.parentElement;
    parent.insertBefore(mm, staticMenu);

    trigger.onclick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        showing = ! showing;

        if (showing) {
            newMenu.style.display = 'block';
        }
        else {
            newMenu.style.display = 'none';
        }
    };
}
